// React
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

// redux
import { connect, useDispatch } from "react-redux";
import { authenticateUser } from "./redux/actions/auth";
import { setLoader } from "./redux/actions/action.types";

// pages
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import Signout from "./pages/Signout/Signout";
import ProfileForm from "./pages/Profile/ProfileForm";

// components
import DynamicAlert from "./components/DynamicAlert/DynamicAlert";
import DynamicLoader from "./components/DynamicLoader.js/DynamicLoader";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// css
import "cropperjs/dist/cropper.css";
import SiteList from "./pages/SiteList/SiteList";
// import RequestPasswordReset from "./pages/RequestPaaswordReset/RequestPasswordReset";
// import ResetPassword from "./pages/ResetPassword/ResetPassword";

const App = ({ authState, authenticateUser }) => {
  const [redirecting, setRedirecting] = useState(false);
  const [showAlert, setShowAlert] = useState(undefined);
  const [keepLoader, setKeepLoader] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAuthentication = async () => {
    const currentToken = localStorage.getItem("token");
    if (currentToken) {
      let response = await authenticateUser(currentToken);
      const query = new URLSearchParams(window.location.search);
      const redirect_url = query.get("redirect_url");
      if (response.status === 200) {
        setKeepLoader(true);
        if (redirect_url) {
          setRedirecting(true);
          setTimeout(() => {
            window.location.href = `${redirect_url}?token=${currentToken}`;
            query.delete("redirect_url");
            history.push(`/signin`);
          }, 500);
        } else {
          window.location.href = "/sites";
        }
      } else {
        localStorage.removeItem("token");
        setShowAlert({
          message: "Session token is invalid or expired. please login again!",
          type: "error",
        });
        if (redirect_url) {
          history.push(`/signin?redirect_url=${redirect_url}`);
        } else {
          history.push(`/signin`);
        }

        console.log("user is not logged in");
      }
    } else {
      console.log("token is missing");
      setRedirecting(false);
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    // const query = new URLSearchParams(window.location.search);
    // const redirect_url = query.get("redirect_url");
    // if (redirect_url) {
    //   localStorage.setItem("redirect_url", redirect_url);
    // }
    if (
      window.location.pathname === "/signin" ||
      window.location.pathname === "/signup"
    ) {
      handleAuthentication();
    } else {
      dispatch(setLoader(false));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (authState.loading || redirecting || keepLoader) {
    return <DynamicLoader />;
  }

  return (
    <>
      {showAlert ? (
        <DynamicAlert
          open={true}
          message={showAlert.message}
          handleClose={() => {
            setShowAlert(undefined);
          }}
          type={showAlert.type}
        />
      ) : null}

      {/* <Header /> */}
      <Switch>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <Route exact path="/signin">
          <Signin setShowAlert={setShowAlert} />
        </Route>
        <Route exact path="/signup">
          <Signup setShowAlert={setShowAlert} />
        </Route>
        <Route exact path="/signout">
          <Signout setShowAlert={setShowAlert} />
        </Route>
        {/* <Route exact path="/request-reset">
          <RequestPasswordReset setShowAlert={setShowAlert} />
        </Route>
        <Route exact path="/reset-password">
          <ResetPassword setShowAlert={setShowAlert} />
        </Route> */}
        <PrivateRoute setShowAlert={setShowAlert} exact path="/profile">
          <ProfileForm setShowAlert={setShowAlert} />
        </PrivateRoute>
        <PrivateRoute setShowAlert={setShowAlert} exact path="/sites">
          <SiteList setShowAlert={setShowAlert} />
        </PrivateRoute>
      </Switch>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  authState: auth,
});

const mapDispatchToProps = {
  authenticateUser: (data) => authenticateUser(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
