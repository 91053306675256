import styled from "styled-components";

export const StyledInputContainer = styled.div`
  input {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(194, 232, 249);
    color: rgb(99, 99, 99);
    font-weight: 500;
    display: block;
    font-size: 1em !important;
    padding: 0em 0.625em;
    width: -webkit-fill-available;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0px;
    border: 0;
    height: 38px;
    &::placeholder {
      color: rgb(150, 150, 150);
      font-weight: 400;
    }
    &:focus {
      outline: none;
    }
  }
`;
