import React from "react";
import { FormHelperText } from "@material-ui/core";
import { StyledInputContainer } from "./styled";

const DynamicInput = ({
  error,
  type,
  style,
  value,
  onChange = () => {},
  placeholder,
  ...props
}) => {
  // const [localType, setLocalType] = useState(type);
  return (
    <StyledInputContainer>
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {/* <TextField
        {...props}
        variant={props.variant || "outlined"}
        type={localType}
        error={!!error}
        style={{
          ...style,
          backgroundColor: "rgba(255,255,255)",
          border: "none",
          outline: "none",
          width: "100%",
          // borderRadius: "5px",
        }}
        InputLabelProps={{
          style: {
            bottom: "75%",
            top: "unset",
          },
        }}
        inputProps={{
          style: {
            verticalAlign: "middle",
            padding: "8px 16px",
            width: "100%",
          },
        }}
        InputProps={{
          endAdornment:
            type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setLocalType(localType === "password" ? "text" : "password")
                  }
                  edge="end"
                >
                  {localType === "password" ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
      /> */}
      {error ? (
        <FormHelperText style={{ fontWeight: "600" }} error={true}>
          {error}
        </FormHelperText>
      ) : null}
    </StyledInputContainer>
  );
};

export default DynamicInput;
