import React from "react";

export const ProfileIcon = ({ height, width, style }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <svg
        viewBox="0 0 70.4 70.4"
        height={height || "65px"}
        width={width || "65px"}
      >
        <circle cx="35.2" cy="35.2" r="35.2" fill="#fff" />
        <circle
          fill="none"
          stroke="#d7ecfc"
          strokeWidth="2"
          strokeLinecap="round"
          strokeMiterlimit="10"
          cx="34.2"
          cy="27.8"
          r="11"
        />
        <path
          fill="none"
          stroke="#d7ecfc"
          strokeWidth="2"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M49.9 54.5c0-8.7-7-15.7-15.7-15.7s-15.7 7-15.7 15.7"
        />
        <g>
          <circle
            fill="none"
            stroke="#096ab4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            cx="35.2"
            cy="25.8"
            r="11"
          />
          <path
            fill="none"
            stroke="#096ab4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            d="M50.9 52.5c0-8.7-7-15.7-15.7-15.7s-15.7 7-15.7 15.7"
          />
        </g>
      </svg>
    </div>
  );
};
