import React from "react";
import { StyledAuthPageContainer } from "../../styles/components";

const AuthPageContainer = ({ style, ...props }) => {
  return (
    <StyledAuthPageContainer style={style} {...props}>
      {props.children}
    </StyledAuthPageContainer>
  );
};

export default AuthPageContainer;
