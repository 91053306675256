import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const DynamicAlert = ({
  open = true,
  handleClose = (f) => f,
  message,
  type = "success",
  duration = 3000,
}) => {
  if (open) {
    setTimeout(() => {
      handleClose();
    }, duration);
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        key={message}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DynamicAlert;
