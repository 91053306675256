import { AppBar, Toolbar, Typography } from "@material-ui/core";
import React from "react";

const Header = ({ title, endComponent = (f) => f }) => {
  return (
    <AppBar id="main-header" position="static">
      <Toolbar>
        <Typography
          variant="h6"
          style={{
            fontFamily: "MICROGME",
          }}
        >
          {title}
        </Typography>
        {endComponent()}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
