import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicSelect from "../../../components/DynamicSelect/DynamicSelect";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const Step3 = ({
  state,
  errors,
  validateStep,
  handleChange,
  availableRegions = [],
  availableCountries = [],
  decrementStep,
  onRegionChange = (ids) => {},
}) => {
  return (
    <>
      <ProfileIcon
        style={{
          marginTop: "18px",
        }}
        height="60px"
        width="60px"
      />
      <p
        style={{
          color: "white",
          fontSize: "14px",
          margin: "20px 1.66667em",
          textAlign: "center",
        }}
      >
        Where are you based?
      </p>
      <div style={{ margin: "17px 0" }}>
        <DynamicSelect
          name="region"
          placeholder="Select a region"
          value={state.region}
          onChange={(e) => {
            handleChange("region", e.target.value);
            onRegionChange([e.target.value]);
          }}
          error={errors.regionError}
          options={availableRegions}
        />
      </div>
      <div style={{ margin: "17px 0 22px 0" }}>
        <DynamicSelect
          name="country"
          placeholder="Select a country"
          value={state.country}
          onChange={(e) => {
            handleChange("country", e.target.value);
          }}
          error={errors.countryError}
          options={availableCountries}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "22px" }}
          onClick={(e) => validateStep(e)}
        >
          Continue
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default Step3;
