const validEmail = (val) => {
  // eslint-disable-next-line
  const reg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  if (val) {
    if (reg.exec(val)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const ValidateTermsAndConditions = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.agreeTermsAndConditions) {
    valid = false;
    errors.push({
      name: "termsAndConditionsError",
      error: "Please accept T&Cs",
    });
  }
  return { valid, errors };
};

export const ValidatePassword = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.password) {
    valid = false;
    errors.push({
      name: "passwordError",
      error: "New Password is required.",
    });
  }
  if (!DATA.confirmPassword) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Password confirmation is required.",
    });
  }

  if (
    DATA.password &&
    DATA.confirmPassword &&
    DATA.password !== DATA.confirmPassword
  ) {
    valid = false;
    errors.push({
      name: "confirmPasswordError",
      error: "Passwords do not match.",
    });
  }
  return { valid, errors };
};

export const ValidateRegistrationStep1 = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.fullName) {
    valid = false;
    errors.push({ name: "fullNameError", error: "Full Name is required." });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email Is Required." });
  }
  if (DATA.email && !validEmail(DATA.email)) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is not valid." });
  }
  return { valid, errors };
};

//STEP 2 REGISTRATION FORM VALIDATION
export const ValidateRegistrationStep2 = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.companyName) {
    valid = false;
    errors.push({
      name: "companyNameError",
      error: "Company Name is required.",
    });
  }
  if (!DATA.companyType) {
    valid = false;
    errors.push({
      name: "companyTypeError",
      error: "Company Type Is Required.",
    });
  }
  return { valid, errors };
};

//STEP 3 REGISTRATION FORM VALIDATION
export const ValidateRegistrationStep3 = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.region) {
    valid = false;
    errors.push({ name: "regionError", error: "Region is required." });
  }
  if (!DATA.country) {
    valid = false;
    errors.push({ name: "countryError", error: "Country is Required." });
  }
  return { valid, errors };
};

//STEP 4 REGISTRATION FORM VALIDATION
export const ValidateRegistrationStep4 = (DATA) => {
  let valid = true;
  let errors = [];
  if (DATA.businessRegions === undefined || DATA.businessRegions.length === 0) {
    valid = false;
    errors.push({
      name: "businessRegionsError",
      error: "Choose at least 1 region.",
    });
  }
  if (
    DATA.businessCountries === undefined ||
    DATA.businessCountries.length === 0
  ) {
    valid = false;
    errors.push({
      name: "businessCountriesError",
      error: "Choose at least 1 country.",
    });
  }
  return { valid, errors };
};

//STEP 5 REGISTRATION FORM VALIDATION
export const ValidateRegistrationStep5 = (DATA) => {
  let valid = true;
  let errors = [];
  if (DATA.interestSectors === undefined || DATA.interestSectors.length === 0) {
    valid = false;
    errors.push({
      name: "interestSectorsError",
      error: "Choose at least 1 sector.",
    });
  }
  return { valid, errors };
};

//STEP 6 REGISTRATION FORM VALIDATION
export const ValidateRegistrationStep6 = (DATA) => {
  let valid = true;
  let errors = [];
  if (DATA.reasonsToJoin === undefined || DATA.reasonsToJoin.length === 0) {
    valid = false;
    errors.push({
      name: "reasonsToJoinError",
      error: "Choose at least 1 activity option.",
    });
  }
  return { valid, errors };
};

//STEP 7 REGISTRATION SUMMARY FORM VALIDATION
export const ValidateRegistrationSummary = (DATA) => {
  let valid = true;
  let errors = [];
  if (!DATA.fullName) {
    valid = false;
    errors.push({ name: "fullNameError", error: "Full Name is required." });
  }
  if (!DATA.email) {
    valid = false;
    errors.push({ name: "emailError", error: "Email Is Required." });
  }
  if (DATA.email && !validEmail(DATA.email)) {
    valid = false;
    errors.push({ name: "emailError", error: "Email is not valid." });
  }
  if (!DATA.companyName) {
    valid = false;
    errors.push({
      name: "companyNameError",
      error: "Company Name is required.",
    });
  }
  if (!DATA.companyType) {
    valid = false;
    errors.push({
      name: "companyTypeError",
      error: "Company Type Is Required.",
    });
  }
  if (!DATA.region) {
    valid = false;
    errors.push({ name: "regionError", error: "Region is required." });
  }
  if (!DATA.country) {
    valid = false;
    errors.push({ name: "countryError", error: "Country is Required." });
  }
  if (DATA.businessRegions === undefined || DATA.businessRegions.length === 0) {
    valid = false;
    errors.push({
      name: "businessRegionsError",
      error: "Choose at least 1 region.",
    });
  }
  if (
    DATA.businessCountries === undefined ||
    DATA.businessCountries.length === 0
  ) {
    valid = false;
    errors.push({
      name: "businessCountriesError",
      error: "Choose at least 1 country.",
    });
  }
  if (DATA.interestSectors === undefined || DATA.interestSectors.length === 0) {
    valid = false;
    errors.push({
      name: "interestSectorsError",
      error: "Choose at least 1 sector.",
    });
  }
  if (DATA.reasonsToJoin === undefined || DATA.reasonsToJoin.length === 0) {
    valid = false;
    errors.push({
      name: "reasonsToJoinError",
      error: "Choose at least 1 activity option.",
    });
  }
  return { valid, errors };
};

//STEP 8 TERMS AND CONDITIONS FORM VALIDATION
// export const ValidateTermsAndConditions = (DATA) => {
//   let valid = true;
//   let errors = [];
//   if (!DATA.agreeTermsAndConditions) {
//     valid = false;
//     errors.push({
//       name: "termsAndConditionsError",
//       error: "Please confirm the Terms And Conditions",
//     });
//   }
//   return { valid, errors };
// };
