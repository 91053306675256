import { CircularProgress } from "@material-ui/core";
import React from "react";

const DynamicLoader = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <span>{message || "Loading please wait..."}</span>
    </div>
  );
};

export default DynamicLoader;
