import React, { useRef, useState } from "react";
import DynamicCropper from "../DynamicCropper/DynamicCropper";
import Compressor from "compressorjs";
import { Button } from "@material-ui/core";

const DynamicImagePicker = ({
  src,
  rounded,
  setImageObject = (f) => f,
  placeholder = "Add image",
  borderRadius,
  width,
  height,
  aspectRatio,
  id,
  headerId,
  error,
  onInputClick = (f) => f,
}) => {
  const imageInput = useRef(null);

  const [imageToBeCropped, setImageToBeCropped] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [showBtnOverlay, setShowBtnOverlay] = useState(false);

  const hideHeader = () => {
    const header = document.getElementById(headerId);
    if (header) {
      header.style = "display:none !important";
    }
  };

  const showHeader = () => {
    const header = document.getElementById(headerId);
    if (header) {
      header.style = "display:block";
    }
  };

  const imagePicker = async (e) => {
    try {
      console.log(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        hideHeader();
      }

      new Compressor(file, {
        width: 1200,
        height: 1200,
        minWidth: 1200,
        minHeight: 1200,
        quality: 0.7,
        success(normalizedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(normalizedFile);
          let encoded = "";
          reader.onloadend = () => {
            hideHeader();

            encoded = reader.result.toString();
            setImageToBeCropped(encoded);

            setFileName(file.name);
            setFileType(file.type);
          };
        },
        error(error) {
          console.log(error);
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {imageToBeCropped ? (
        <DynamicCropper
          src={imageToBeCropped}
          onCancel={() => {
            showHeader();
            setImageToBeCropped("");
            setFileName("");
            setFileType("");
          }}
          setCroppedSrc={(src) => {
            showHeader();
            setImageObject({
              file: src,
              fileName: fileName,
              fileType: fileType,
            });
            setImageToBeCropped("");
            setShowBtnOverlay(false);
          }}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
        />
      ) : null}

      <input
        required
        ref={imageInput}
        type="file"
        style={{ display: "none", margin: "0 10px", height: 0 }}
        id={id}
        accept="image/*"
        name="profile_pic"
        onChange={(e) => {
          imagePicker(e);
          e.target.value = null;
        }}
        capture="filesystem"
      />

      {src ? (
        <>
          <div
            style={{
              background: "#fff",
              width: width,
              height: height,
              borderRadius: rounded ? "50%" : borderRadius,
              display: showBtnOverlay ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              maxWidth: width,
              maxHeight: height,
              flexDirection: "column",
              border: "0.1px solid #aaa",
            }}
          >
            <Button
              size="small"
              fill="outline"
              color="grey"
              style={{ color: "grey", width: "70px" }}
              onClick={() => {
                setImageObject({ file: "", fileName: "", fileType: "" });
                setImageToBeCropped("");
                setShowBtnOverlay(false);
              }}
            >
              Remove
            </Button>
            <Button
              size="small"
              fill="outline"
              color="grey"
              style={{ color: "grey", width: "70px" }}
              onClick={(e) => {
                if (imageInput.current) {
                  imageInput.current.click();
                }
              }}
            >
              Change
            </Button>
            <Button
              size="small"
              fill="outline"
              color="grey"
              style={{ color: "grey", width: "70px" }}
              onClick={() => {
                setShowBtnOverlay(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div
            style={{
              width: width,
              height: height,
              borderRadius: rounded ? "50%" : borderRadius,
              display: showBtnOverlay ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              maxWidth: width,
              maxHeight: height,
              flexDirection: "column",
            }}
          >
            <img
              src={src}
              style={{
                width: width,
                height: height,
                borderRadius: rounded ? "50%" : borderRadius,
                objectFit: "cover",
                display: showBtnOverlay ? "none" : "",
              }}
              alt="prof"
              onClick={() => setShowBtnOverlay(true)}
            />
          </div>
        </>
      ) : (
        <label htmlFor={id}>
          <div
            style={{
              background: "#fff",
              width: width,
              height: height,
              borderRadius: rounded ? "50%" : borderRadius,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              maxWidth: width,
              maxHeight: height,
              border: error ? "1px dashed red" : "1px solid #aaa",
            }}
            onClick={() => {
              onInputClick();
            }}
          >
            <span style={{ color: error ? "red" : "grey" }}>
              {placeholder || "Add image"}
            </span>
          </div>
        </label>
      )}
    </>
  );
};

export default DynamicImagePicker;
