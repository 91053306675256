import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicMultiSelect from "../../../components/DynamicMultiSelect";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const Step5 = ({
  state,
  errors,
  validateStep,
  handleChange,
  decrementStep,
  availableInterestSectors = [],
  availableInterestSubSectors = [],
  handleOnSectorChange = (sectors) => {},
  setAvailableInterestSubSectors,
  setState,
}) => {
  return (
    <>
      <ProfileIcon
        style={{
          marginTop: "18px",
        }}
        height="60px"
        width="60px"
      />
      <p
        style={{
          color: "white",
          fontSize: "14px",
          margin: "20px 1.66667em",
          textAlign: "center",
        }}
      >
        In order to personalize your experience, please tell us which sectors
        you are interested in.
      </p>
      <div style={{ margin: "17px 0" }}>
        <DynamicMultiSelect
          name="interestSectors"
          placeholder="Select sectors"
          handleOnTagRemove={(id) => {
            let tag = availableInterestSectors.find(
              (sector) => sector.id === id
            );
            const removedSector = tag.sectorSubs.map((t) => t.id);
            setState({
              ...state,
              interestSectors: state.interestSectors?.filter(
                (i) => i !== tag.id
              ),
              interestSubSectors: state.interestSubSectors?.filter(
                (i) => !removedSector.includes(i)
              ),
            });
            setAvailableInterestSubSectors(
              availableInterestSubSectors.filter(
                (i) => !removedSector.includes(i.id)
              )
            );
          }}
          value={state.interestSectors}
          onChange={(e) => {
            if (state.interestSectors.includes(e.target.value)) {
              return;
            }
            let selectedSector = availableInterestSectors.find(
              (s) => s.id === parseInt(e.target.value)
            );
            handleChange("interestSectors", [
              ...state.interestSectors,
              e.target.value,
            ]);

            handleOnSectorChange(selectedSector?.sectorSubs);
          }}
          error={errors.interestSectorsError}
          options={availableInterestSectors}
          tags={
            availableInterestSectors &&
            availableInterestSectors.filter((sector) =>
              state.interestSectors.includes(sector.id)
            )
          }
        />
      </div>
      <div style={{ margin: "17px 0 22px 0" }}>
        <DynamicMultiSelect
          name="interestSubSectors"
          placeholder="Select sub sectors"
          value={state.interestSubSectors}
          handleOnTagRemove={(id) => {
            let filteredSubSector = state.interestSubSectors.filter(
              (sector) => sector !== id
            );
            handleChange("interestSubSectors", filteredSubSector);
          }}
          onChange={(e) => {
            if (state.interestSubSectors.includes(e.target.value)) {
              return;
            }
            handleChange("interestSubSectors", [
              ...state.interestSubSectors,
              e.target.value,
            ]);
          }}
          error={errors.interestSubSectorsError}
          options={availableInterestSubSectors}
          tags={
            availableInterestSubSectors &&
            availableInterestSubSectors.filter((sector) =>
              state.interestSubSectors.includes(sector.id)
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "22px" }}
          onClick={(e) => validateStep(e)}
        >
          Continue
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default Step5;
