import ApiService from "../../apis";
import { emitResponse } from "../../utils/BaseResponse";
import {
  setUser,
  setIsAuthenticated,
  setError,
  setLoader,
} from "./action.types";

export const authenticateUser = (token) => async (dispatch) => {
  dispatch(setLoader(true));
  const api = new ApiService();
  try {
    let res = await api.fetchAuthUser(token);
    if (res.data.status) {
      dispatch(setUser(res.data));
      dispatch(setIsAuthenticated(true));
      dispatch(setLoader(false));
      return emitResponse(res.data, null, res.status);
    } else {
      dispatch(setLoader(false));
      return emitResponse(null, "Invalid token", 400);
    }
  } catch (error) {
    console.log(error.message.response);
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
    dispatch(setError(true));
    dispatch(setLoader(false));
    return emitResponse(null, error, 404);
  }
};

export const signinUser = (data) => async (dispatch) => {
  const api = new ApiService();
  try {
    let res = await api.signin(data);
    if (res.data.success) {
      dispatch(setUser(res.data));
      dispatch(setIsAuthenticated(true));
      return emitResponse(res.data, null, res.status);
    } else if (res.data.error) {
      dispatch(setError(true));
      return emitResponse(null, res.data.error, 400);
    }
  } catch (error) {
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
    dispatch(setError(true));
    return emitResponse(null, error, 404);
  }
};

export const signupUser = (data) => async (dispatch) => {
  const api = new ApiService();
  try {
    let res = await api.signup(data);
    if (res.data.success) {
      dispatch(setUser(res.data.data));
      dispatch(setIsAuthenticated(true));
      return emitResponse(res.data, null, res.status);
    } else if (res.data.error) {
      dispatch(setError(true));
      return emitResponse(null, res.data.error, 400);
    }
  } catch (error) {
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
    dispatch(setError(true));
    return emitResponse(null, error, 400);
  }
};

export const signOut = (token) => async (dispatch) => {
  const api = new ApiService();
  try {
    let res = await api.signout(token);
    console.log(res);
    // if (res.status) {
    dispatch(setUser(null));
    dispatch(setIsAuthenticated(false));
    return emitResponse(null, null, 200);
    // }
  } catch (error) {
    dispatch(setError(true));
    return emitResponse(null, error, 404);
  }
};
