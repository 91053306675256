import { Button } from "@material-ui/core";
import React from "react";

const DynamicButton = ({ style, ...props }) => {
  return (
    <>
      <Button
        {...props}
        style={{
          padding: "7px",
          backgroundColor: "#127ABD",
          borderRadius: "25px",
          fontWeight: "600",
          textTransform: "capitalize",
          color: "white",
          width: "100%",
          fontSize: "14px",
          ...style,
        }}
      >
        {props.children}
      </Button>
    </>
  );
};

export default DynamicButton;
