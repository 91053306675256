import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import { authenticateUser } from "../../redux/actions/auth";
import DynamicLoader from "../DynamicLoader.js/DynamicLoader";

const PrivateRoute = ({ setShowAlert, authenticateUser, ...props }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkAuthentication = async () => {
    setLoading(true);
    const currentToken = localStorage.getItem("token");

    if (currentToken) {
      let response = await authenticateUser(currentToken);
      if (response.status === 200) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
        setShowAlert({
          message: "Session token is invalid or expired. please login again!",
          type: "error",
        });
      }
    } else {
      setIsAuthorized(false);
      setShowAlert({
        message: "Session token is invalid or expired. please login again!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line

  if (loading) {
    return <DynamicLoader />;
  }

  if (!isAuthorized) {
    return <Redirect to="/signin" />;
  }
  return <Route {...props}>{props.children}</Route>;
};

const mapDispatchToProps = {
  authenticateUser: (data) => authenticateUser(data),
};

export default connect(null, mapDispatchToProps)(PrivateRoute);
