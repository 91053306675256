import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicInput from "../../../components/DynamicInput/DynamicInput";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const CreatePassword = ({
  state,
  errors,
  validateStep,
  handleChange,
  decrementStep,
}) => {
  return (
    <>
      <ProfileIcon
        style={{
          marginTop: "18px",
        }}
        height="70px"
        width="70px"
      />
      <p
        style={{
          color: "white",
          paddingTop: "16px",
          fontSize: "18px",
          margin: 0,
          textAlign: "center",
        }}
      >
        Your Details
      </p>
      <div style={{ margin: "10px 0" }}>
        <DynamicInput
          name="password"
          placeholder="Password"
          type={"password"}
          style={{ width: "100%" }}
          value={state.password}
          onChange={(e) => {
            handleChange("password", e.target.value);
          }}
          error={errors.passwordError}
        />
      </div>
      <div style={{ margin: "10px 0" }}>
        <DynamicInput
          name="confirmPassword"
          placeholder="Confirm password"
          type={"password"}
          style={{ width: "100%" }}
          value={state.confirmPassword}
          onChange={(e) => {
            handleChange("confirmPassword", e.target.value);
          }}
          error={errors.confirmPasswordError}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px 0",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "16px" }}
          onClick={(e) => validateStep(e)}
        >
          Continue
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default CreatePassword;
