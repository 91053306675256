import { Button } from "@material-ui/core";
import React from "react";
import SiteCard from "../../components/SiteCard/SiteCard";
import Header from "../../layout/Header";
import styles from "./styles.module.css";

const SiteList = () => {
  return (
    <>
      <Header
        title={"OCO CONNECT"}
        endComponent={() => (
          <Button
            size="small"
            style={{
              color: "white",
              marginLeft: "auto",
            }}
            onClick={() => {
              window.location.href = "/signout";
            }}
          >
            Logout
          </Button>
        )}
      />
      <div
        style={{
          padding: "1rem",
        }}
      >
        <h2>Available sites</h2>
        <div className={styles.siteContainer}>
          <SiteCard
            title={"Bizzyou"}
            link={`https://engagedemo.ococonnect.com/?token=${localStorage.getItem(
              "token"
            )}`}
          />
          <SiteCard
            title={"Gateway"}
            link={`https://devhome.ococonnect.com/?token=${localStorage.getItem(
              "token"
            )}`}
          />
        </div>
      </div>
    </>
  );
};

export default SiteList;
