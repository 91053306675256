import styled from "styled-components";
import { theme } from "../utils/theme";

export const DynamicSelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DynamicSelectTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;

  span {
    background-color: #fff;
    border-radius: 10px;
    font-size: 10px;
    padding: 2px 10px;
    font-weight: 400;
    color: ${theme.primaryTextColor};
    margin: 10px 10px 0 0;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;

    span {
      padding: 0;
      margin: 0;
      margin-left: 5px;
      font-size: 12px;
      cursor: pointer;
      position: relative;
      bottom: 0px;
      font-weight: 500;
      box-shadow: none;
    }
  }
`;

export const DynamicSelectInput = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(194, 232, 249);
  font-weight: ${({ value }) => (value ? "500" : "400")};
  cursor: pointer;
  color: ${({ value }) => (value ? "rgb(99, 99, 99)" : "rgb(150, 150, 150)")};
  line-height: 1.1;
  height: 38px;
  padding: 0em 0.625em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DynamicOptionsWrapper = styled.div`
  position: absolute;
  //   display: none;
  overflow-x: hidden;
  max-height: 180px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10001;
  border: 1px solid ${theme.lightBlueColor};
  background-color: #fff;
  padding: 0;

  &::-webkit-scrollbar {
    width: 0em;
  }
  input {
    padding: 12px 16px;
    border: none;
    background-color: #fff;
    outline: none;
    // position: fixed;
    border-bottom: 1px solid ${theme.lightBlueColor};
    width: 100%;
  }
  span.checked {
    background-color: ${theme.primaryTextColor};
    height: 12px;
    width: 12px;
    position: relative;
    float: right;
    right: 10px;
    top: 4px;
    border: 1px solid ${theme.primaryTextColor};
  }
  span.non-checked {
    background-color: #fff;
    height: 12px;
    width: 12px;
    position: relative;
    float: right;
    right: 10px;
    top: 4px;
    border: 1px solid ${theme.lightBlueColor};
  }
`;

export const DynamicOption = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  z-index: 1001;
  span.name {
    width: 90%;
    display: inline-block;
  }
  &:hover {
    background-color: ${theme.lightBlueColor};
  }
`;
