import React from "react";
import { CheckboxContainer } from "../../styles/dynamicCheck";

const DynamicCheckbox = ({
  label,
  checked = false,
  onChange = (bool) => {},
  error = undefined,
}) => {
  return (
    <>
      <CheckboxContainer>
        <span
          className={checked ? "checked" : "unchecked"}
          onClick={() => {
            onChange(!checked);
          }}
        ></span>
        <span className="label">{label}</span>
        {error && <small>{error}</small>}
      </CheckboxContainer>
    </>
  );
};

export default DynamicCheckbox;
