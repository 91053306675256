import styled from "styled-components";

export const StyledAuthCard = styled.div`
  max-width: ${({ isSummary }) => (isSummary ? "90%" : "700px")} !important;
  width: ${({ isSummary }) => (isSummary ? "100%" : "50%")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(191, 203, 230, 0.8);
  border-radius: 20px;
  box-shadow: 0px 20px 20px rgb(0 0 0 / 40%);
  overflow: unset;
  padding: 2.5em;
`;

export const StyledAuthPageContainer = styled.div`
  background-image: url(/assets/images/background.png);
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(18, 122, 189);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: unset;
  padding: 2.5em 0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCardBody = styled.div`
  width: ${({ isSummary }) => (isSummary ? "100%" : "300px")} !important;
  margin: 0 auto;
  overflow: hidden;
`;
