import axios from "axios";

const AUTH_API_HOST = process.env.REACT_APP_AUTH_API_HOST;
const OCO_API_HOST = process.env.REACT_APP_OCO_API_HOST;

axios.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
axios.defaults.timeout = 8000;

class ApiService {
  fetchAuthUser(token) {
    return axios.post(`${AUTH_API_HOST}/CheckToken?token=${token}`, {
      token: token,
    });
  }

  signin(data) {
    return axios.post(`${AUTH_API_HOST}/login`, data);
  }

  signup(data) {
    return axios.post(`${AUTH_API_HOST}/register`, data);
  }

  signout(token) {
    return axios.POST(`${AUTH_API_HOST}/logout`, {
      token: token,
    });
  }
  requestPasswordReset(data) {
    return axios.post(`${AUTH_API_HOST}/password/forgot`, data);
  }

  resetPassword(data) {
    return axios.post(`${AUTH_API_HOST}/password/reset`, data);
  }

  getGlobalRegions() {
    return axios.get(`${OCO_API_HOST}/api/Countries/GetGlobalRegions`);
  }
  getCompanyTypes() {
    return axios.get(`${OCO_API_HOST}/api/Registration/GetCompanyTypes`);
  }

  getCountriesByRegionIds(regionIds) {
    return axios.post(`${OCO_API_HOST}/api/Countries/GetCountries`, regionIds);
  }

  getSectors() {
    return axios.get(`${OCO_API_HOST}/api/Registration/GetSectors`);
  }

  getReasons() {
    return axios.get(`${OCO_API_HOST}/api/Registration/GetReasons`);
  }
}

export default ApiService;
