import styled from "styled-components";

export const RegSummaryMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin: 1rem 0;
  }
`;

export const RegSummaryCol1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 17px;
  div,
  input {
    width: 100%;
  }
`;

export const RegSummaryCol2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 17px;

  div,
  input {
    width: 100%;
  }
`;

export const RegSummaryCol3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  span.subtitle {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export const RegistrationIconWrapper = styled.div`
  margin: 25px auto;
  max-width: 700px;
  svg {
    width: 700px;
  }
`;

export const RegistrationWrapper = styled.i`
  display: block;
  height: 61px;
  width: 61px;
  margin: 0 auto 20px auto;
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

export const AccountFormWrapper = styled.div`
  background-color: rgba(191, 203, 230, 0.8);
  border-radius: 15px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.4);
  padding: 2.5em;
  max-width: ${(props) => (props.widthLarge ? "auto" : "750px")};
  margin: 0 auto;

  p {
    color: #ffffff;
    margin-bottom: 1.7em;
  }
`;

export const TandCHeader = styled.div`
  color: #ffffff;
  font-size: 1em;
  margin-bottom: 1.7em;
  text-align: center;
`;

export const AccountFormWidth = styled.div`
  margin: 0 auto;
  width: ${(props) => (props.widthLarge ? "auto" : "300px")};
  color: #ffffff;
  font-size: 1.2em;
  margin-bottom: 1.7em;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;

export const TermsAndConditionsStyled = styled.div`
  background-color: white;
  text-align: left;
  padding: 0.7em 1.7em;
  margin-bottom: 1.7em;
  border-radius: 15px;
  max-height: 300px;
  overflow: scroll;
  p {
    color: #2f3030 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  &::-webkit-scrollbar {
    width: 0em;
  }
`;
