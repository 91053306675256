import styled from "styled-components";
import { theme } from "../utils/theme";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 15px 0;
  position: relative;
  span.checked {
    margin-right: 10px;
    width: 14px;
    height: 14px;
    border: 1px solid ${theme.primaryTextColor};
    background-color: ${theme.primaryTextColor};
  }
  span.unchecked {
    margin-right: 10px;
    width: 14px;
    height: 14px;
    border: 1px solid ${theme.primaryTextColor};
    background-color: #fff;
  }
  span.label {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    display: inline-block;
  }
  small {
    font-size: 12px;
    position: absolute;
    top: 20px;
    display: block;
    color: #ff0000;
    font-weight: 500;
  }
`;
