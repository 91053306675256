import { FormHelperText } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  DynamicOption,
  DynamicOptionsWrapper,
  DynamicSelectContainer,
  DynamicSelectInput,
} from "../../styles/dynamicSelect";
import { useOnClickOutside } from "../../utils/hooks";

const DynamicSelect = ({
  value,
  onChange = (val) => val,
  options = [],
  name,
  error,
  style,
  onFocus = (f) => f,
  placeholder,
  ...props
}) => {
  const ref = useRef(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempOptions, setTempOptions] = useState([]);

  useEffect(() => {
    setTempOptions(options);
  }, [options]);

  const getValueFromOptions = (val) => {
    const option = options.find(
      (option) => parseInt(option.id) === parseInt(val)
    );
    return option ? option.name : "";
  };

  useOnClickOutside(ref, () => {
    setOpenOptions(false);
    setSearchValue("");
    setTempOptions(options);
  });
  return (
    <>
      {/* <Autocomplete
        {...props}
        id="state-select-demo"
        style={{ ...style }}
        options={options.filter((o) => o.name)}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderOption={(option) => (
          <React.Fragment>{option.name}</React.Fragment>
        )}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              name: params.inputValue,
            });
          }
          return filtered;
        }}
        inputValue={value ? value : ""}
        onFocus={() => onFocus()}
        onInputChange={(e, val) => {
          onChange(val);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            label={"select"}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      /> */}
      <DynamicSelectContainer ref={ref}>
        <DynamicSelectInput
          value={value}
          onClick={() => {
            setOpenOptions(!openOptions);
          }}
        >
          {value ? getValueFromOptions(value) : placeholder}
        </DynamicSelectInput>
        {openOptions && (
          <DynamicOptionsWrapper>
            <input
              type="text"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setTempOptions(
                  options.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                );
              }}
            />
            {tempOptions.map((option) => (
              <DynamicOption
                key={option.id}
                onClick={() => {
                  onChange({ target: { value: option.id } });
                  setOpenOptions(false);
                  setSearchValue("");
                  setTempOptions(options);
                }}
              >
                {option.name}
              </DynamicOption>
            ))}
          </DynamicOptionsWrapper>
        )}
      </DynamicSelectContainer>
      {error ? (
        <FormHelperText
          style={{ marginLeft: "0", fontWeight: "600" }}
          error={true}
        >
          <div id="register-state-code-error">{error}</div>
        </FormHelperText>
      ) : null}
    </>
  );
};

export default DynamicSelect;
