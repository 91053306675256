import { CardActions } from "@material-ui/core";
import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const RegistrationWelcome = ({ incrementStep = (f) => f }) => {
  return (
    <>
      <ProfileIcon
        style={{
          marginTop: "18px",
        }}
        height="70px"
        width="70px"
      />
      <h2
        style={{
          color: "white",
          paddingTop: "16px",
          fontSize: "18px",
          margin: 0,
          textAlign: "center",
        }}
      >
        Registration
      </h2>
      <p
        style={{
          color: "white",
          padding: "24px",
          fontSize: "14px",
          margin: 0,
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        Welcome to OCO Connect. Follow the instructions to create your account
        and personalise your experience.
      </p>
      <CardActions
        style={{
          padding: "0 16px 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <DynamicButton
          style={{
            width: "100%",
            // backgroundColor: "#3f51b5",
            color: "white",
          }}
          onClick={(e) => incrementStep(e)}
        >
          Start Registration
        </DynamicButton>
        <small
          style={{
            position: "relative",
            color: "#fff",
            marginTop: "25px",
            fontWeight: "600",
            fontSize: "15px",
          }}
        >
          Already have an account?{" "}
          <a
            href="/signin"
            style={{
              color: "#fff",
            }}
          >
            Log in here
          </a>
        </small>
      </CardActions>
    </>
  );
};

export default RegistrationWelcome;
