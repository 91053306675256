import { Card, CardActions } from "@material-ui/core";
import React from "react";
import styles from "./styles.module.css";

const SiteCard = ({ link, title }) => {
  return (
    <Card
      className={styles.siteCard}
      style={{
        background: "linear-gradient(90deg,#FD297A ,#9424F0)",
        height: "200px",
        width: "350px",
        marginRight: "20px",
        cursor: "pointer",
      }}
      onClick={() => {
        let a = document.createElement("a");
        a.href = link;
        a.target = "_blank";
        a.click();
      }}
    >
      <CardActions style={{ padding: "22px" }}>
        <span
          style={{
            color: "white",
            textDecoration: "none",
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          {title}
        </span>
      </CardActions>
    </Card>
  );
};

export default SiteCard;
