import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signOut } from "../../redux/actions/auth";

const Signout = ({ signOut }) => {
  const handleSignOut = async () => {
    const query = new URLSearchParams(window.location.search);
    const redirect_url = query.get("redirect_url");
    let currentToken = localStorage.getItem("token");
    localStorage.clear();
    await signOut(currentToken);
    if (redirect_url) {
      window.location.href = `/signin?redirect_url=${redirect_url}`;
    } else {
      window.location.href = "/signin";
    }
  };

  useEffect(() => {
    handleSignOut();
  }, []); // eslint-disable-line

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "70vh",
        flexDirection: "column",
      }}
    >
      <CircularProgress />
      <span>Logging out please wait...</span>
    </div>
  );
};

const mapDispatchToProps = {
  signOut: (data) => signOut(data),
};

export default connect(null, mapDispatchToProps)(Signout);
