import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicInput from "../../../components/DynamicInput/DynamicInput";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const Step1 = ({
  state,
  errors,
  decrementStep = (f) => f,
  handleChange = (f) => f,
  validateStep = (f) => f,
}) => {
  return (
    <>
      <ProfileIcon height="60px" width="60px" />
      <p
        style={{
          color: "white",
          fontSize: "14px",
          margin: "20px 1.66667em",
          textAlign: "center",
        }}
      >
        Your Details
      </p>
      <div style={{ margin: "17px 0" }}>
        <DynamicInput
          name="fullName"
          placeholder="Full Name"
          style={{ width: "100%" }}
          value={state.fullName}
          onChange={(e) => {
            handleChange("fullName", e.target.value);
          }}
          error={errors.fullNameError}
        />
      </div>
      <div style={{ margin: "17px 0 22px 0" }}>
        <DynamicInput
          name="email"
          placeholder="Email Address"
          style={{ width: "100%" }}
          value={state.email}
          onChange={(e) => {
            handleChange("email", e.target.value);
          }}
          error={errors.emailError}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "22px" }}
          onClick={(e) => validateStep(e)}
        >
          Continue
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default Step1;
