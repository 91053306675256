import React, { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import styles from "./styles.module.css";
import { Button } from "@material-ui/core";
import ReplayIcon from "@material-ui/icons/Replay";

const DynamicCropper = ({
  src,
  setCroppedSrc = (f) => f,
  onCancel = (f) => f,
  width,
  height,
}) => {
  const cropperRef = useRef(null);
  const [submitClicked, setSubmitClicked] = useState(false);

  const onCrop = async () => {
    setSubmitClicked(true);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    let src = cropper.getCroppedCanvas().toDataURL();
    setCroppedSrc(src);
  };

  if (!src) {
    return null;
  }
  return (
    <div className={styles.cropperOverlay}>
      <div className={styles.cropperBtnStrip}>
        <div>
          <Button
            size="small"
            onClick={() => {
              const imageElement = cropperRef?.current;
              const cropper = imageElement?.cropper;
              cropper.rotate(-90);
            }}
            className={styles.rotateBtn}
          >
            <ReplayIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              const imageElement = cropperRef?.current;
              const cropper = imageElement?.cropper;
              cropper.rotate(90);
            }}
            className={styles.rotateBtnReverse}
          >
            <ReplayIcon />
          </Button>
        </div>
        <div>
          <Button size="small" onClick={onCancel} className={styles.cropperBtn}>
            Cancel
          </Button>
          <Button
            size="small"
            disabled={submitClicked}
            onClick={onCrop}
            className={styles.cropperBtn}
          >
            Submit
          </Button>
        </div>
      </div>
      <Cropper
        src={src}
        style={{
          height: "100%",
          width: "100%",
          position: "fixed",
        }}
        aspectRatio={
          parseInt(width.split("px")[0]) / parseInt(height.split("px")[0])
        }
        rotatable={true}
        guides={true}
        ref={cropperRef}
        className={styles.cropperContainer}
      />
    </div>
  );
};

export default DynamicCropper;
