import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicMultiSelect from "../../../components/DynamicMultiSelect";
import { ProfileIcon } from "../../../components/ProfileIcon/ProfileIcon";

const Step4 = ({
  state,
  errors,
  validateStep,
  handleChange,
  availableReasonsToJoin = [],
  decrementStep,
}) => {
  return (
    <>
      <ProfileIcon
        style={{
          marginTop: "18px",
        }}
        height="60px"
        width="60px"
      />
      <p
        style={{
          color: "white",
          fontSize: "14px",
          margin: "20px 1.66667em",
          textAlign: "center",
        }}
      >
        What are you looking to do?
      </p>

      <div style={{ margin: "17px 0 22px 0" }}>
        <DynamicMultiSelect
          name="reasonsToJoin"
          placeholder="Select reasons"
          value={state.reasonsToJoin}
          handleOnTagRemove={(id) => {
            let filteredReasons = state.reasonsToJoin.filter(
              (country) => country !== id
            );
            handleChange("reasonsToJoin", filteredReasons);
          }}
          onChange={(e) => {
            if (state.reasonsToJoin.includes(e.target.value)) {
              return;
            }
            handleChange("reasonsToJoin", [
              ...state.reasonsToJoin,
              e.target.value,
            ]);
          }}
          error={errors.reasonsToJoinError}
          options={availableReasonsToJoin}
          tags={
            availableReasonsToJoin &&
            availableReasonsToJoin.filter((reason) =>
              state.reasonsToJoin.includes(reason.id)
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "22px" }}
          onClick={(e) => validateStep(e)}
        >
          Continue
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default Step4;
