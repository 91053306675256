import { RegistrationIconWrapper } from "../../../styles/register";

const RegistrationProgressBar = ({ step }) => {
  return (
    <RegistrationIconWrapper>
      {step === 1 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 884.91 53.7">
          <path
            fill="#749ed3"
            d="M868.19,10.13a16.71,16.71,0,0,0-16.68,15.72H716.59a16.7,16.7,0,0,0-33.35,0H548.32a16.7,16.7,0,0,0-33.34,0H380.06a16.7,16.7,0,0,0-33.35,0H211.79a16.7,16.7,0,0,0-33.35,0H43.52a16.72,16.72,0,1,0,0,2H178.44a16.7,16.7,0,0,0,33.35,0H346.71a16.7,16.7,0,0,0,33.35,0H515a16.7,16.7,0,0,0,33.34,0H683.24a16.7,16.7,0,0,0,33.35,0H851.51a16.72,16.72,0,1,0,16.68-17.72Z"
          />
          <circle fill="#749ed3" cx="26.85" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="26.85" cy="26.85" r="16.72" />
          <text
            fontSize="22px"
            fontFamily="Avenir"
            fill="#fff"
            transform="translate(188.98 33.66)"
          >
            2
          </text>
          <text
            fill="#3078bd"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(22.74 33.66)"
          >
            1
          </text>
          <text
            fontSize="22px"
            fontFamily="Avenir"
            fill="#fff"
            transform="translate(356.84 33.66)"
          >
            3
          </text>
          <text
            fontSize="22px"
            fontFamily="Avenir"
            fill="#fff"
            transform="translate(524.34 33.66)"
          >
            4
          </text>
          <text
            fontSize="22px"
            fontFamily="Avenir"
            fill="#fff"
            transform="translate(694.07 33.66)"
          >
            5
          </text>
          <text
            fontSize="22px"
            fontFamily="Avenir"
            fill="#fff"
            transform="translate(861.72 33.66)"
          >
            6
          </text>
        </svg>
      )}
      {step === 2 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 874.78 53.7">
          <path
            fill="#749ed3"
            d="M858.06,10.13a16.7,16.7,0,0,0-16.67,15.72H706.47a16.7,16.7,0,0,0-33.35,0H538.2a16.7,16.7,0,0,0-33.35,0H369.93a16.7,16.7,0,0,0-33.35,0H201.66a16.7,16.7,0,0,0-33.34,0H33.4a16.72,16.72,0,1,0,0,2H168.32a16.7,16.7,0,0,0,33.34,0H336.58a16.7,16.7,0,0,0,33.35,0H504.85a16.7,16.7,0,0,0,33.35,0H673.12a16.7,16.7,0,0,0,33.35,0H841.39a16.71,16.71,0,1,0,16.67-17.72Z"
          />
          <circle fill="#749ed3" cx="185.72" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="185.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="16.72" cy="26.85" r="16.72" />
          <text
            fill="#0c69b2"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(179.86 33.66)"
          >
            2
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(346.72 33.66)"
          >
            3
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(514.22 33.66)"
          >
            4
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(683.94 33.66)"
          >
            5
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(851.59 33.66)"
          >
            6
          </text>
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
        </svg>
      )}
      {step === 3 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 874.78 53.7">
          <path
            fill="#749ed3"
            d="M858.06,10.13a16.7,16.7,0,0,0-16.67,15.72H706.47a16.7,16.7,0,0,0-33.35,0H538.2a16.7,16.7,0,0,0-33.35,0H369.93a16.7,16.7,0,0,0-33.35,0H201.66a16.7,16.7,0,0,0-33.34,0H33.4a16.72,16.72,0,1,0,0,2H168.32a16.7,16.7,0,0,0,33.34,0H336.58a16.7,16.7,0,0,0,33.35,0H504.85a16.7,16.7,0,0,0,33.35,0H673.12a16.7,16.7,0,0,0,33.35,0H841.39a16.71,16.71,0,1,0,16.67-17.72Z"
          />
          <circle fill="#fff" cx="16.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="184.89" cy="26.85" r="16.77" />
          <circle fill="#749ed3" cx="353.72" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="353.72" cy="26.85" r="16.72" />
          <text
            fill="#0c69b2"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(347.72 33.66)"
          >
            3
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(514.22 33.66)"
          >
            4
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(683.94 33.66)"
          >
            5
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(851.59 33.66)"
          >
            6
          </text>
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="177.13 27.18 181.84 31.89 192.06 21.67"
          />
        </svg>
      )}
      {step === 4 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 874.78 53.7">
          <path
            fill="#749ed3"
            d="M858.06,10.13a16.7,16.7,0,0,0-16.67,15.72H706.47a16.7,16.7,0,0,0-33.35,0H538.2a16.7,16.7,0,0,0-33.35,0H369.93a16.7,16.7,0,0,0-33.35,0H201.66a16.7,16.7,0,0,0-33.34,0H33.4a16.72,16.72,0,1,0,0,2H168.32a16.7,16.7,0,0,0,33.34,0H336.58a16.7,16.7,0,0,0,33.35,0H504.85a16.7,16.7,0,0,0,33.35,0H673.12a16.7,16.7,0,0,0,33.35,0H841.39a16.71,16.71,0,1,0,16.67-17.72Z"
          />
          <circle fill="#fff" cx="16.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="184.89" cy="26.85" r="16.77" />
          <circle fill="#749ed3" cx="521.72" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="521.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="353.25" cy="26.85" r="16.72" />
          <text
            fill="#0c69b2"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(514.22 33.66)"
          >
            4
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(683.94 33.66)"
          >
            5
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(851.59 33.66)"
          >
            6
          </text>
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="177.13 27.18 181.84 31.89 192.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="346.13 27.18 350.84 31.89 361.06 21.67"
          />
        </svg>
      )}
      {step === 5 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 874.78 53.7">
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="177.13 27.18 181.84 31.89 192.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="346.13 27.18 350.84 31.89 361.06 21.67"
          />
          <path
            fill="#749ed3"
            d="M858.06,10.13a16.7,16.7,0,0,0-16.67,15.72H706.47a16.7,16.7,0,0,0-33.35,0H538.2a16.7,16.7,0,0,0-33.35,0H369.93a16.7,16.7,0,0,0-33.35,0H201.66a16.7,16.7,0,0,0-33.34,0H33.4a16.72,16.72,0,1,0,0,2H168.32a16.7,16.7,0,0,0,33.34,0H336.58a16.7,16.7,0,0,0,33.35,0H504.85a16.7,16.7,0,0,0,33.35,0H673.12a16.7,16.7,0,0,0,33.35,0H841.39a16.71,16.71,0,1,0,16.67-17.72Z"
          />
          <circle fill="#fff" cx="16.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="184.89" cy="26.85" r="16.77" />
          <circle fill="#fff" cx="521.72" cy="26.85" r="16.72" />
          <circle fill="#749ed3" cx="689.72" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="689.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="353.25" cy="26.85" r="16.72" />
          <text
            fill="#0c69b2"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(683.94 33.66)"
          >
            5
          </text>
          <text
            fill="#fff"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(851.59 33.66)"
          >
            6
          </text>
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="177.13 27.18 181.84 31.89 192.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="346.13 27.18 350.84 31.89 361.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="514.13 27.18 518.84 31.89 529.06 21.67"
          />
        </svg>
      )}
      {step === 6 && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 884.57 53.7">
          <path
            fill="#749ed3"
            d="M858.06,10.13a16.7,16.7,0,0,0-16.67,15.72H706.47a16.7,16.7,0,0,0-33.35,0H538.2a16.7,16.7,0,0,0-33.35,0H369.93a16.7,16.7,0,0,0-33.35,0H201.66a16.7,16.7,0,0,0-33.34,0H33.4a16.72,16.72,0,1,0,0,2H168.32a16.7,16.7,0,0,0,33.34,0H336.58a16.7,16.7,0,0,0,33.35,0H504.85a16.7,16.7,0,0,0,33.35,0H673.12a16.7,16.7,0,0,0,33.35,0H841.39a16.71,16.71,0,1,0,16.67-17.72Z"
          />
          <circle fill="#fff" cx="16.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="184.89" cy="26.85" r="16.77" />
          <circle fill="#fff" cx="521.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="689.72" cy="26.85" r="16.72" />
          <circle fill="#749ed3" cx="857.72" cy="26.85" r="26.85" />
          <circle fill="#fff" cx="857.72" cy="26.85" r="16.72" />
          <circle fill="#fff" cx="353.25" cy="26.85" r="16.72" />
          <text
            fill="#0c69b2"
            fontSize="22px"
            fontFamily="Avenir"
            transform="translate(851.59 33.66)"
          >
            6
          </text>
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="9.13 27.18 13.84 31.89 24.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="177.13 27.18 181.84 31.89 192.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="346.13 27.18 350.84 31.89 361.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="514.13 27.18 518.84 31.89 529.06 21.67"
          />
          <polyline
            fill="none"
            stroke="#0c69b2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            points="682.13 27.18 686.84 31.89 697.06 21.67"
          />
        </svg>
      )}
    </RegistrationIconWrapper>
  );
};

export default RegistrationProgressBar;
