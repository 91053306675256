/* eslint-disable import/no-anonymous-default-export */
import { SET_PROFILE, SET_ERROR } from "../actions/action.types";

const initialState = {
  profile: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
