import { CardActions, CardContent, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import AuthCard from "../../components/AuthCard/AuthCard";
import AuthPageContainer from "../../components/AuthPageContainer/AuthPageContainer";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import { ProfileIcon } from "../../components/ProfileIcon/ProfileIcon";
import { signinUser } from "../../redux/actions/auth";
import { isEmail } from "../../utils/validators";
import styles from "./styles.module.css";

const Signin = ({ signinUser, setShowAlert }) => {
  const query = new URLSearchParams(window.location.search);
  let redirect_url = query.get("redirect_url");
  const [signingIn, setSigningIn] = useState(false);
  const [signinObj, setSigninObj] = useState({
    email: "",
    password: "",
  });

  const [errorObj, setErrorObj] = useState({
    email_error: "",
    password_error: "",
  });

  const handleChange = (name) => (event) => {
    setSigninObj({ ...signinObj, [name]: event.target.value });
  };

  const errorHandler = () => {
    let email_error = "";
    let password_error = "";
    let error = false;
    if (signinObj.email === "") {
      email_error = "Email is required";
      error = true;
    }
    if (signinObj.email && !isEmail(signinObj.email)) {
      email_error = "Enter a valid email address";
      error = true;
    }
    if (signinObj.password === "") {
      password_error = "Password is required";
      error = true;
    }
    setErrorObj({ email_error, password_error });
    return error;
  };

  const handleSignin = async (e) => {
    e.preventDefault();

    let hasError = errorHandler();
    if (hasError) {
      return;
    }
    setSigningIn(true);
    const signinResponse = await signinUser({
      email: signinObj.email,
      password: signinObj.password,
    });
    if (signinResponse.data?.success) {
      setSigningIn(false);
      localStorage.setItem("token", signinResponse.data.data);
      console.log(signinObj);

      window.location.reload();
    } else {
      setSigningIn(false);
      setShowAlert({
        message:
          (signinResponse.error && signinResponse.message) ||
          "Login credentials are invalid",
        type: "error",
      });
    }
  };
  return (
    <AuthPageContainer>
      <h1
        style={{
          fontFamily: "MICROGME",
          color: "#fff",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "25px",
        }}
      >
        OCO CONNECT
      </h1>
      <AuthCard>
        <CardContent className={styles.cardContent}>
          <ProfileIcon
            style={{
              marginTop: "18px",
            }}
            height="70px"
            width="70px"
          />
          <h5
            style={{
              color: "white",
              paddingTop: "16px",
              fontSize: "22px",
              textAlign: "center",
              margin: "0px",
            }}
          >
            Signin
          </h5>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "5px 0" }}>
              <DynamicInput
                variant="outlined"
                placeholder="Email"
                type="email"
                style={{
                  width: "100%",
                }}
                error={errorObj.email_error}
                value={signinObj.email}
                onChange={handleChange("email")}
                onFocus={() => setErrorObj({ ...errorObj, email_error: "" })}
              />
            </div>
            <div style={{ margin: "5px 0" }}>
              <DynamicInput
                variant="outlined"
                placeholder="Password"
                style={{
                  width: "100%",
                }}
                type="password"
                error={errorObj.password_error}
                value={signinObj.password}
                onChange={handleChange("password")}
                onFocus={() => setErrorObj({ ...errorObj, password_error: "" })}
              />
            </div>
            {/* <small
              style={{
                position: "relative",
                color: "#fff",
                fontSize: "15px",
                marginLeft: "auto",
              }}
            >
              <a
                href={"/request-reset"}
                style={{
                  color: "#3f51b5",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
              >
                Forgot password?
              </a>
            </small> */}
          </CardContent>
          <CardActions
            style={{
              padding: "0 16px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {signingIn ? (
              <DynamicButton
                style={{
                  width: "100%",
                  color: "white",
                }}
              >
                <CircularProgress
                  color="#ffffff"
                  style={{ width: "24px", height: "24px" }}
                />
              </DynamicButton>
            ) : (
              <DynamicButton
                style={{
                  width: "100%",
                  color: "white",
                }}
                onClick={handleSignin}
              >
                Signin
              </DynamicButton>
            )}

            <small
              style={{
                margin: "auto",
                position: "relative",
                color: "#fff",
                marginTop: "25px",
                fontWeight: "600",
                fontSize: "15px",
              }}
            >
              Don't' have an account?{" "}
              <a
                href={
                  redirect_url
                    ? `/signup?redirect_url=${redirect_url}`
                    : "/signup"
                }
                style={{
                  color: "#fff",
                }}
              >
                create one
              </a>
            </small>
          </CardActions>
        </CardContent>
      </AuthCard>
    </AuthPageContainer>
  );
};

const mapDispatchToProps = {
  signinUser: (data) => signinUser(data),
};
export default connect(null, mapDispatchToProps)(Signin);
