export const theme = {
  primaryTextColor: "#136FB5",
  subPrimaryTextColor: "#C2E8F9",
  secondaryTextColor: "#54555A",
  primaryGray: "#949597",
  secondaryGray: "#cccacc",
  primaryBlack: "#000D1E",
  primaryPillColor: "#032b55",
  paidPillColor: "#1DAFED",
  dangerColor: "#FA5D50",
  lightBlueColor: "#C2E8F9",
};
