import {
  AccountFormWidth,
  RegistrationWrapper,
  TandCHeader,
  TermsAndConditionsStyled,
} from "../../../styles/register";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicCheckbox from "../../../components/DynamicCheckbox";

const TermsAndConditions = ({
  state,
  errors,
  validateStep,
  handleChange,
  decrementStep,
  agreeTermsAndConditions,
  setState,
}) => {
  return (
    <>
      <AccountFormWidth widthLarge>
        <RegistrationWrapper>
          <svg viewBox="0 0 70.38 70.38">
            <circle cx="35.19" cy="35.19" r="35.19" fill="#fff" />
            <path
              stroke="#d5ebfc"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M48.04 54.4v2H20.33V17.98h1"
            />
            <path
              stroke="#0068b6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M21.33 15.98h27.71V54.4H21.33z"
            />
            <path
              stroke="#d5ebfc"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M24.23 24.41h12.92M24.23 32.74h12.92M24.23 41.08h12.92M24.23 49.41h12.92M41.01 24.36l1.11 1.12 2.9-2.9M41.01 49.36l1.11 1.12 2.9-2.9M41.01 41.03l1.11 1.11 2.9-2.89M41.01 32.69l1.11 1.12 2.9-2.9"
            />
            <path
              stroke="#0068b6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M25.23 23.41h12.92M25.23 31.74h12.92M25.23 40.08h12.92M25.23 48.41h12.92M42.01 23.36l1.11 1.12 2.9-2.9M42.01 48.36l1.11 1.12 2.9-2.9M42.01 40.03l1.11 1.11 2.9-2.89M42.01 31.69l1.11 1.12 2.9-2.9"
            />
          </svg>
        </RegistrationWrapper>
        <TandCHeader>Terms and Conditions</TandCHeader>
        <TermsAndConditionsStyled>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </TermsAndConditionsStyled>
        <DynamicCheckbox
          label="I agree to the terms and conditions."
          checked={state.agreeTermsAndConditions}
          onChange={(bool) => {
            setState({ ...state, agreeTermsAndConditions: bool });
          }}
          error={errors.termsAndConditionsError}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "10px 0",
            width: "100%",
          }}
        >
          <DynamicButton
            style={{ marginBottom: "16px" }}
            onClick={(e) => validateStep(e)}
          >
            Confirm and submit
          </DynamicButton>
          <DynamicButton
            style={{ marginLeft: 0, backgroundColor: "#000" }}
            onClick={(e) => decrementStep(e)}
          >
            Back
          </DynamicButton>
        </div>
      </AccountFormWidth>
    </>
  );
};

export default TermsAndConditions;
