import {
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
} from "@material-ui/core";
import React, { useState } from "react";
import DynamicButton from "../../components/DynamicButton/DynamicButton";
import DynamicImagePicker from "../../components/DynamicImagePicker/DynamicImagePicker";
import DynamicInput from "../../components/DynamicInput/DynamicInput";
import { isEmail } from "../../utils/validators";

const ProfileForm = () => {
  const [profileState, setProfileState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
    avatar: {
      file: "",
      fileType: "",
      fileName: "",
    },
  });

  const [errorState, setErrorState] = useState({
    first_name_error: "",
    last_name_error: "",
    email_error: "",
    company_name_error: "",
    avatar_error: "",
  });

  const changeProfileState = (name) => (event) => {
    setProfileState({
      ...profileState,
      [name]: event.target.value,
    });
  };

  const validateProfileState = () => {
    let error = false;
    let errObj = errorState;
    if (!profileState.first_name) {
      errObj.first_name_error = "First name is required";
      error = true;
    }
    if (!profileState.last_name) {
      errObj.last_name_error = "Last name is required";
      error = true;
    }
    if (!profileState.email) {
      errObj.email_error = "Email is required";
      error = true;
    }

    if (profileState.email && !isEmail(profileState.email)) {
      errObj.email_error = "Email is invalid";
      error = true;
    }

    if (!profileState.company_name) {
      errObj.company_name_error = "Company name is required";
      error = true;
    }
    if (!profileState.avatar.file) {
      errObj.avatar_error = "Avatar is required";
      error = true;
    }
    setErrorState({ ...errObj });
    return error;
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    const hasError = validateProfileState();
    if (!hasError) {
      console.log(profileState, "..");
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3rem",
      }}
    >
      <Card
        style={{
          width: "40%",
        }}
      >
        <h4
          style={{
            color: "black",
            padding: "16px",
            fontSize: "24px",
            margin: 0,
          }}
        >
          Update profile
        </h4>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <DynamicImagePicker
              src={profileState.avatar.file}
              width="120px"
              height="120px"
              rounded
              id={"user_img"}
              placeholder="Add Avatar"
              setImageObject={(obj) =>
                setProfileState({ ...profileState, avatar: obj })
              }
              headerId="main-header"
              error={errorState.avatar_error}
              onInputClick={() => {
                setErrorState({ ...errorState, avatar_error: "" });
              }}
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <DynamicInput
              variant="outlined"
              label="First name"
              placeholder="First name"
              type="text"
              style={{ width: "100%" }}
              value={profileState.first_name}
              onChange={changeProfileState("first_name")}
              error={errorState.first_name_error}
              onFocus={() => {
                setErrorState({ ...errorState, first_name_error: "" });
              }}
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <DynamicInput
              variant="outlined"
              label="Last name"
              placeholder="Last name"
              type="text"
              style={{ width: "100%" }}
              value={profileState.last_name}
              onChange={changeProfileState("last_name")}
              error={errorState.last_name_error}
              onFocus={() => {
                setErrorState({ ...errorState, last_name_error: "" });
              }}
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <DynamicInput
              variant="outlined"
              label="Email"
              placeholder="Email"
              type="email"
              style={{ width: "100%" }}
              value={profileState.email}
              onChange={changeProfileState("email")}
              error={errorState.email_error}
              onFocus={() => {
                setErrorState({ ...errorState, email_error: "" });
              }}
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <DynamicInput
              variant="outlined"
              label="Company name"
              placeholder="Company name"
              type="text"
              style={{ width: "100%" }}
              value={profileState.company_name}
              onChange={changeProfileState("company_name")}
              error={errorState.company_name_error}
              onFocus={() => {
                setErrorState({ ...errorState, company_name_error: "" });
              }}
            />
          </div>
        </CardContent>
        <CardActions
          style={{
            padding: "0 16px 16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {false ? (
            <DynamicButton
              style={{
                width: "100%",
                backgroundColor: "#3f51b5",
                color: "white",
              }}
            >
              <CircularProgress
                color="#ffffff"
                style={{ width: "24px", height: "24px" }}
              />
            </DynamicButton>
          ) : (
            <DynamicButton
              style={{
                width: "100%",
                backgroundColor: "#3f51b5",
                color: "white",
              }}
              onClick={handleProfileUpdate}
            >
              Update profile
            </DynamicButton>
          )}
        </CardActions>
      </Card>
    </Container>
  );
};

export default ProfileForm;
