import { FormHelperText } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import {
  DynamicOption,
  DynamicOptionsWrapper,
  DynamicSelectContainer,
  DynamicSelectInput,
  DynamicSelectTags,
} from "../../styles/dynamicSelect";
import { useOnClickOutside } from "../../utils/hooks";

const DynamicMultiSelect = ({
  value,
  onChange = (val) => val,
  options = [],
  name,
  error,
  style,
  onFocus = (f) => f,
  placeholder,
  tags = [],
  handleOnTagRemove = (id) => {},
  ...props
}) => {
  const ref = useRef(null);
  const [openOptions, setOpenOptions] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempOptions, setTempOptions] = useState([]);

  useEffect(() => {
    setTempOptions(options);
  }, [options]);

  useOnClickOutside(ref, () => {
    setOpenOptions(false);
    setSearchValue("");
    setTempOptions(options);
  });

  return (
    <>
      <DynamicSelectContainer ref={ref}>
        <DynamicSelectInput
          value={tags.length ? true : false}
          onClick={() => {
            setOpenOptions(!openOptions);
          }}
        >
          {value ? `${tags.length} options selected` : placeholder}
        </DynamicSelectInput>
        {openOptions && (
          <DynamicOptionsWrapper>
            <input
              type="text"
              value={searchValue}
              placeholder="Search..."
              onChange={(e) => {
                setSearchValue(e.target.value);
                setTempOptions(
                  options.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                );
              }}
            />
            {tempOptions?.map((option) => (
              <>
                <DynamicOption
                  key={option.id}
                  onClick={() => {
                    if (tags.find((tag) => tag.id === option.id)) {
                      handleOnTagRemove(option.id);
                      return;
                    }
                    onChange({ target: { value: option.id } });
                    // setOpenOptions(false);
                  }}
                >
                  <span className="name">{option.name}</span>
                  <span
                    className={
                      tags.map((t) => t.id).includes(option.id)
                        ? "checked"
                        : "non-checked"
                    }
                  ></span>
                </DynamicOption>
              </>
            ))}
          </DynamicOptionsWrapper>
        )}
      </DynamicSelectContainer>
      {error ? (
        <FormHelperText
          style={{ marginLeft: "0", fontWeight: "600" }}
          error={true}
        >
          <div id="register-state-code-error">{error}</div>
        </FormHelperText>
      ) : null}
      {tags.length > 0 && (
        <DynamicSelectTags>
          {tags?.map((t, i) => {
            return (
              <>
                <span key={i}>
                  {t.name}
                  <span
                    onClick={() => {
                      handleOnTagRemove(t.id);
                    }}
                  >
                    x
                  </span>
                </span>
              </>
            );
          })}
        </DynamicSelectTags>
      )}
    </>
  );
};

export default DynamicMultiSelect;
