import React from "react";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";
import DynamicCheckbox from "../../../components/DynamicCheckbox";
import DynamicInput from "../../../components/DynamicInput/DynamicInput";
import DynamicMultiSelect from "../../../components/DynamicMultiSelect";
import DynamicSelect from "../../../components/DynamicSelect/DynamicSelect";
import {
  RegSummaryCol1,
  RegSummaryCol2,
  RegSummaryCol3,
  RegSummaryMainContainer,
} from "../../../styles/register";

const Summary = ({
  state,
  setState,
  errors,
  validateStep,
  handleChange,
  decrementStep,
  onRegionChange = (ids, bool) => {},
  availableReasonsToJoin = [],
  availableCountries = [],
  availableRegions = [],
  availableCompanyTypes = [],
  availableInterestSectors = [],
  availableInterestSubSectors = [],
  availableBusinessCountries = [],
  availableBusinessRegions = [],
  setAvailableInterestSubSectors,
  handleOnSectorChange,
}) => {
  return (
    <>
      <RegSummaryMainContainer>
        <RegSummaryCol1>
          <p>Your details</p>

          <DynamicInput
            style={{ width: "100%" }}
            value={state.fullName}
            onChange={(e) => {
              handleChange("fullName", e.target.value);
            }}
            error={errors.fullNameError}
          />
          <DynamicInput
            value={state.email}
            style={{ width: "100%" }}
            onChange={(e) => {
              handleChange("email", e.target.value);
            }}
            error={errors.emailError}
          />
          <p>Where you are based</p>
          <DynamicSelect
            value={state.region}
            options={availableRegions}
            onChange={(e) => {
              handleChange("region", e.target.value);
              onRegionChange([e.target.value]);
            }}
            error={errors.regionError}
          />
          <DynamicSelect
            value={state.country}
            options={availableCountries}
            onChange={(e) => {
              handleChange("country", e.target.value);
            }}
            error={errors.countryError}
          />
          <p>Sectors you are interested in</p>
          <DynamicMultiSelect
            handleOnTagRemove={(id) => {
              let tag = availableInterestSectors.find(
                (sector) => sector.id === id
              );
              const removedSector = tag.sectorSubs.map((t) => t.id);
              setState({
                ...state,
                interestSectors: state.interestSectors?.filter(
                  (i) => i !== tag.id
                ),
                interestSubSectors: state.interestSubSectors?.filter(
                  (i) => !removedSector.includes(i)
                ),
              });
              setAvailableInterestSubSectors(
                availableInterestSubSectors.filter(
                  (i) => !removedSector.includes(i.id)
                )
              );
            }}
            value={state.interestSectors}
            onChange={(e) => {
              if (state.interestSectors.includes(e.target.value)) {
                return;
              }
              let selectedSector = availableInterestSectors.find(
                (s) => s.id === parseInt(e.target.value)
              );
              handleChange("interestSectors", [
                ...state.interestSectors,
                e.target.value,
              ]);

              handleOnSectorChange(selectedSector?.sectorSubs);
            }}
            error={errors.interestSectorsError}
            options={availableInterestSectors}
            tags={
              availableInterestSectors &&
              availableInterestSectors.filter((sector) =>
                state.interestSectors.includes(sector.id)
              )
            }
          />
          <DynamicMultiSelect
            value={state.interestSubSectors}
            handleOnTagRemove={(id) => {
              let filteredSubSector = state.interestSubSectors.filter(
                (sector) => sector !== id
              );
              handleChange("interestSubSectors", filteredSubSector);
            }}
            onChange={(e) => {
              if (state.interestSubSectors.includes(e.target.value)) {
                return;
              }
              handleChange("interestSubSectors", [
                ...state.interestSubSectors,
                e.target.value,
              ]);
            }}
            error={errors.interestSubSectorsError}
            options={availableInterestSubSectors}
            tags={
              availableInterestSubSectors &&
              availableInterestSubSectors.filter((sector) =>
                state.interestSubSectors.includes(sector.id)
              )
            }
          />
        </RegSummaryCol1>
        <RegSummaryCol2>
          <p>Company details</p>

          <DynamicInput value={state.companyName} />
          <DynamicSelect
            value={state.companyType}
            options={availableCompanyTypes}
            onChange={(e) => {
              handleChange("companyType", e.target.value);
            }}
            error={errors.companyTypeError}
          />
          <p>Locations that you are seeking to do business</p>

          <DynamicMultiSelect
            handleOnTagRemove={(id) => {
              let filteredRegions = state.businessRegions.filter(
                (reg) => reg !== id
              );
              let filteredCountries = availableBusinessCountries
                .filter((country) => country.regionId !== id)
                .map((c) => c.id);
              let _cs = state.businessCountries.filter((c) =>
                filteredCountries.includes(c)
              );
              handleChange("businessRegions", filteredRegions);
              handleChange("businessCountries", _cs);
              onRegionChange(filteredRegions, true);
            }}
            value={state.businessRegions}
            onChange={(e) => {
              if (state.businessRegions.includes(e.target.value)) {
                return;
              }
              onRegionChange([...state.businessRegions, e.target.value], true);
              handleChange("businessRegions", [
                ...state.businessRegions,
                e.target.value,
              ]);
            }}
            error={errors.businessRegionsError}
            options={availableBusinessRegions}
            tags={
              availableBusinessRegions &&
              availableBusinessRegions.filter((region) =>
                state.businessRegions.includes(region.id)
              )
            }
          />
          <DynamicMultiSelect
            value={state.businessCountries}
            handleOnTagRemove={(id) => {
              let filteredCountries = state.businessCountries.filter(
                (country) => country !== id
              );
              handleChange("businessCountries", filteredCountries);
            }}
            onChange={(e) => {
              if (state.businessCountries.includes(e.target.value)) {
                return;
              }
              handleChange("businessCountries", [
                ...state.businessCountries,
                e.target.value,
              ]);
            }}
            error={errors.businessCountriesError}
            options={availableBusinessCountries}
            tags={
              availableBusinessCountries &&
              availableBusinessCountries.filter((country) =>
                state.businessCountries.includes(country.id)
              )
            }
          />
          <p>What you are looking to do</p>

          <DynamicMultiSelect
            value={state.reasonsToJoin}
            handleOnTagRemove={(id) => {
              let filteredReasons = state.reasonsToJoin.filter(
                (country) => country !== id
              );
              handleChange("reasonsToJoin", filteredReasons);
            }}
            onChange={(e) => {
              if (state.reasonsToJoin.includes(e.target.value)) {
                return;
              }
              handleChange("reasonsToJoin", [
                ...state.reasonsToJoin,
                e.target.value,
              ]);
            }}
            error={errors.reasonsToJoinError}
            options={availableReasonsToJoin}
            tags={
              availableReasonsToJoin &&
              availableReasonsToJoin.filter((reason) =>
                state.reasonsToJoin.includes(reason.id)
              )
            }
          />
        </RegSummaryCol2>
        <RegSummaryCol3>
          <p>Communication preferences: </p>
          <span className="subtitle">
            Would you like us to notify you of new alerts, weekly newsletters,
            marketing, and approaches from other users (you can edit these
            settings later)
          </span>
          <DynamicCheckbox
            label="Allow communication"
            checked={state.communicationPreference}
            onChange={(bool) => {
              setState({ ...state, communicationPreference: bool });
            }}
          />
          <p>Find out more</p>
          <span className="subtitle">Request more information on:</span>
          <DynamicCheckbox
            label="Our business intelligence tools"
            checked={state.intelligencePreference}
            onChange={(bool) => {
              setState({ ...state, intelligencePreference: bool });
            }}
          />

          <DynamicCheckbox
            label="Our global interaction service"
            checked={state.interactionPreference}
            onChange={(bool) => {
              setState({ ...state, interactionPreference: bool });
            }}
          />
          <DynamicCheckbox
            label="Our Marketplace"
            checked={state.marketplacePreference}
            onChange={(bool) => {
              setState({ ...state, marketplacePreference: bool });
            }}
          />
        </RegSummaryCol3>
      </RegSummaryMainContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px 0",
          width: "100%",
        }}
      >
        <DynamicButton
          style={{ marginBottom: "16px" }}
          onClick={(e) => validateStep(e)}
        >
          Confirm and submit
        </DynamicButton>
        <DynamicButton
          style={{ marginLeft: 0, backgroundColor: "#000" }}
          onClick={(e) => decrementStep(e)}
        >
          Back
        </DynamicButton>
      </div>
    </>
  );
};

export default Summary;
